export enum ErrorCodes {
  Access = 'access',
  Application = 'application',
  Auth = 'auth',
  Corrupted = 'corrupted',
  External = 'external',
  FlowViolation = 'flowViolation',
  List = 'list',
  Network = 'network',
  Runtime = 'runtime',
  Unknown = 'unknown',
  Validation = 'validation',
}

export const errorCodesDictionary: {
  -readonly [key in ErrorCodes]: Record<string, any>;
} = {
  access: {
    generic: 'Access denied to the resource',
  },
  application: {
    generic: 'Application error',
    missingEnvVars: 'Missing environment variables',
    routerLoop: 'Router loop detected',
  },
  auth: {
    noSession: 'You must be logged in',
  },
  corrupted: {
    generic: 'Data is corrupted',
  },
  external: {
    aws: {
      generic: 'AWS service error',
      s3: {
        generic: 'AWS S3 service error',
        validation: {
          fileKey: 'File key is missing',
          generic: 'AWS S3 service data validation failed',
        },
      },
    },
    cloudinary: {
      generic: 'Cloudinary service error',
    },
    email: {
      generic: 'Email service error',
    },
    generic: 'External service error',
    ministry: {
      generic: 'Ministry service error',
    },
    openai: {
      chat: {
        completion: {
          generic: 'OpenAI chat completion service error',
        },
      },
      generic: 'OpenAI service error',
    },
    slack: {
      generic: 'Slack service error',
      validation: {
        generic: 'Slack service data validation failed',
        message: 'Slack message is missing',
        title: 'Slack title is missing',
      },
    },
    telnyx: {
      auth: {
        connectionId: 'Telnyx service connection ID is wrong',
      },
      generic: 'Telnyx service error',
      orderNumber: {
        generic: 'Failed to order a fax number',
      },
      receive: {
        failed: 'Failed fax status',
      },
      send: {
        failed: 'Failed fax status',
        generic: 'Failed to send a fax',
      },
      update: {
        generic: 'Failed to update fax status',
      },
      validation: {
        data: 'Data is missing',
        faxId: 'Fax ID is missing',
        faxNumber: 'Fax number is missing',
        faxNumberId: 'Fax number ID is missing',
        generic: 'Telnyx service data validation failed',
      },
    },
  },
  flowViolation: {
    addPatientToCompany: {
      alreadyInCompany: 'Patient is already in the company',
      incorrectDetails:
        'Incorrect details, please review. Patient with this email is already registered on PocketMD.',
      noUserFound: 'User was not found',
    },
    checkUserMembership: {
      alreadyMember: 'User is already a member',
      noUserFound: 'User was not found',
    },
    createCalendarEventBooking: {
      invalidHealthCard: 'Health card is invalid',
    },
    getCompanyMembers: {
      notOnboarded: 'Company is not onboarded',
    },
    inviteUserByDoctor: {
      alreadyInvited: 'User is already invited',
      userExists: 'User already exists',
    },
    task: {
      alreadyResolved: 'Task is already resolved',
    },
  },
  list: {
    address: {
      generic: 'Address processing error',
      notFound: {
        generic: 'Address was not found',
      },
      validation: {
        generic: 'Address data validation failed',
        id: 'Address ID is missing',
      },
    },
    appointment: {
      generic: 'Appointment processing error',
      notFound: {
        doctor: 'Appointment doctor was not found',
        generic: 'Appointment was not found',
      },
      validation: {
        cancel: {
          noReason: 'Appointment cancel reason is missing',
        },
        generic: 'Appointment data validation failed',
        id: 'Appointment ID is missing',
      },
    },
    appointmentDraft: {
      generic: 'Appointment draft processing error',
      notFound: {
        generic: 'Appointment draft was not found',
      },
      validation: {
        generic: 'Appointment draft data validation failed',
        id: 'Appointment draft ID is missing',
      },
    },
    appointmentRequest: {
      generic: 'Appointment request processing error',
      notFound: {
        generic: 'Appointment request was not found',
      },
      validation: {
        generic: 'Appointment request data validation failed',
        id: 'Appointment request ID is missing',
      },
    },
    archive: {
      generic: 'Archive processing error',
      notFound: {
        generic: 'Archive was not found',
      },
      validation: {
        generic: 'Archive data validation failed',
        id: 'Archive ID is missing',
      },
    },
    archiveItem: {
      generic: 'Archive Item processing error',
      notFound: {
        generic: 'Archive Item was not found',
      },
      validation: {
        generic: 'Archive Item data validation failed',
        id: 'Archive Item ID is missing',
      },
    },
    booking: {
      generic: 'Booking processing error',
      notFound: {
        generic: 'Booking was not found',
      },
      validation: {
        alreadyBooked: 'Booking is already exists',
        generic: 'Booking data validation failed',
        id: 'Booking ID is missing',
      },
    },
    calendar: {
      generic: 'Calendar processing error',
      notFound: {
        generic: 'Calendar was not found',
      },
      validation: {
        generic: 'Calendar data validation failed',
        id: 'Calendar ID is missing',
      },
    },
    calendarEvent: {
      generic: 'Calendar event processing error',
      notFound: {
        generic: 'Calendar event was not found',
      },
      validation: {
        generic: 'Calendar event data validation failed',
        id: 'Event ID is missing',
      },
    },
    chat: {
      generic: 'Chat processing error',
      notFound: {
        generic: 'Chat was not found',
      },
      validation: {
        generic: 'Chat data validation failed',
        id: 'Chat ID is missing',
      },
    },
    chatMessage: {
      generic: 'Chat message processing error',
      notFound: {
        generic: 'Chat message was not found',
      },
      validation: {
        generic: 'Chat message data validation failed',
        id: 'Chat message ID is missing',
      },
    },
    clinic: {
      generic: 'Clinic processing error',
      notFound: {
        generic: 'Clinic was not found',
      },
      validation: {
        generic: 'Clinic data validation failed',
        id: 'Clinic ID is missing',
      },
    },
    company: {
      generic: 'Company processing error',
      notFound: {
        generic: 'Company was not found',
      },
      validation: {
        generic: 'Company data validation failed',
        id: 'Company ID is missing',
      },
    },
    companyLogo: {
      generic: 'Company logo processing error',
      notFound: {
        generic: 'Company logo was not found',
      },
      validation: {
        generic: 'Company logo data validation failed',
        id: 'Company logo ID is missing',
      },
    },
    dataImport: {
      generic: 'Data import processing error',
      notFound: {
        generic: 'Data import was not found',
      },
      validation: {
        generic: 'Data import data validation failed',
        id: 'Data import ID is missing',
      },
    },
    doctor: {
      generic: 'Doctor processing error',
      notFound: {
        generic: 'Doctor was not found',
      },
      validation: {
        generic: 'Doctor data validation failed',
        id: 'Doctor ID is missing',
      },
    },
    document: {
      generic: 'Document processing error',
      notFound: {
        generic: 'Document was not found',
      },
      validation: {
        generic: 'Document data validation failed',
        id: 'Document ID is missing',
      },
    },
    fax: {
      generic: 'Fax processing error',
      notFound: {
        generic: 'Fax was not found',
      },
      validation: {
        faxNumber: 'Fax number is missing',
        generic: 'Fax data validation failed',
        id: 'Fax ID is missing',
      },
    },
    file: {
      generic: 'File processing error',
      notFound: {
        generic: 'File was not found',
      },
      validation: {
        generic: 'File data validation failed',
        id: 'File ID is missing',
      },
    },
    form: {
      generic: 'Form processing error',
      notFound: {
        generic: 'Form was not found',
      },
      validation: {
        generic: 'Form data validation failed',
        id: 'Form ID is missing',
      },
    },
    healthCard: {
      generic: 'Health card processing error',
      notFound: {
        generic: 'Health card was not found',
      },
      validation: {
        generic: 'Health card data validation failed',
        id: 'Health card ID is missing',
      },
    },
    inboundFax: {
      generic: 'Inbound fax processing error',
      notFound: {
        generic: 'Inbound fax was not found',
      },
      validation: {
        faxNumber: 'Inbound fax number is missing',
        generic: 'Inbound fax data validation failed',
        id: 'Inbound fax ID is missing',
      },
    },
    leaseAgreement: {
      generic: 'Lease agreement processing error',
      notFound: {
        generic: 'Lease agreement was not found',
      },
      validation: {
        generic: 'Lease agreement data validation failed',
        id: 'Lease agreement ID is missing',
      },
    },
    masterLicense: {
      generic: 'Master license processing error',
      notFound: {
        generic: 'Master license was not found',
      },
      validation: {
        generic: 'Master license data validation failed',
        id: 'Master license ID is missing',
      },
    },
    medicationItem: {
      generic: 'Medication item processing error',
      notFound: {
        generic: 'Medication item was not found',
      },
    },
    member: {
      generic: 'Member processing error',
      notFound: {
        generic: 'Member was not found',
      },
      validation: {
        generic: 'Member data validation failed',
        id: 'Member ID is missing',
        notDoctor: 'Member is not a doctor',
        notPatient: 'Member is not a patient',
      },
    },
    patient: {
      generic: 'Patient processing error',
      notFound: {
        generic: 'Patient was not found',
      },
      validation: {
        generic: 'Patient data validation failed',
        id: 'Patient ID is missing',
      },
    },
    role: {
      generic: 'Role processing error',
      notFound: {
        generic: 'Role was not found',
      },
      validation: {
        generic: 'Role data validation failed',
        id: 'Role ID is missing',
      },
    },
    schedule: {
      generic: 'Schedule processing error',
      notFound: {
        generic: 'Schedule was not found',
      },
      validation: {
        generic: 'Schedule data validation failed',
        id: 'Schedule ID is missing',
      },
    },
    stepper: {
      generic: 'Stepper processing error',
      notFound: {
        generic: 'Stepper was not found',
      },
      validation: {
        generic: 'Stepper data validation failed',
        id: 'Stepper ID is missing',
      },
    },
    stepperProg: {
      generic: 'Stepper progress processing error',
      notFound: {
        generic: 'Stepper progress was not found',
      },
      validation: {
        generic: 'Stepper progress data validation failed',
        id: 'Stepper progress ID is missing',
      },
    },
    stepperStep: {
      generic: 'Stepper step processing error',
      notFound: {
        generic: 'Stepper step was not found',
      },
      validation: {
        generic: 'Stepper step data validation failed',
        id: 'Stepper step ID is missing',
      },
    },
    stepperStepProg: {
      generic: 'Stepper step progress processing error',
      notFound: {
        generic: 'Stepper step progress was not found',
      },
      validation: {
        generic: 'Stepper step progress data validation failed',
        id: 'Stepper step progress ID is missing',
      },
    },
    task: {
      generic: 'Task processing error',
      notFound: {
        generic: 'Task was not found',
      },
      validation: {
        generic: 'Task data validation failed',
        id: 'Task ID is missing',
      },
    },
    testOrder: {
      generic: 'Test order processing error',
      notFound: {
        generic: 'Test order was not found',
      },
      validation: {
        generic: 'Test order data validation failed',
        id: 'Test order ID is missing',
      },
    },
    user: {
      generic: 'User processing error',
      notFound: {
        generic: 'User was not found',
      },
      validation: {
        generic: 'User data validation failed',
        id: 'User ID is missing',
      },
    },
    userInvite: {
      generic: 'User invite processing error',
      notFound: {
        generic: 'User invite was not found',
      },
      validation: {
        generic: 'User invite data validation failed',
        id: 'User invite ID is missing',
      },
    },
  },
  network: {
    generic: 'Network error',
    server: {
      unavailable: 'Application is temporarily unavailable',
    },
  },
  runtime: {
    unhandled: 'Sorry, something went wrong.',
  },
  unknown: {
    generic: 'Something went wrong',
    gql: 'Something went wrong',
    unhandled: 'Something went wrong',
  },
  validation: {
    generic: 'Data validation failed',
  },
};
