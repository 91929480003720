import * as Sentry from '@sentry/nextjs';
import { Component } from 'react';

import Text from '@/components/data-display/Text';

class GlobalErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(
      `🔴 CDC ~ errorInfo:`,
      JSON.stringify(errorInfo, Object.getOwnPropertyNames(errorInfo), 2)
    );
    console.error(
      `🔴 CDC ~ error:`,
      JSON.stringify(error, Object.getOwnPropertyNames(error), 2)
    );
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <Text>Sorry, something went wrong.</Text>
        </div>
      );
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    return this.props.children;
  }
}

export default GlobalErrorBoundary;
