import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';

import { useCurrentUser } from '@/auth/useCurrentUser';
import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import { useLogUserSessionMutation } from '@/generated/graphql';
import { handleError } from '@/utils/errors';

// import { useSessionTracker } from '@/hooks/useSessionTracker';

export const WithUserSession = ({ children }: { children: ReactNode }) => {
  const currentUser = useCurrentUser();
  const [logSession] = useLogUserSessionMutation();

  // useSessionTracker(currentUser?.id, currentUser?.firstName as string);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = async (url: string) => {
      const sessionId = localStorage.getItem('sessionId');

      if (!sessionId) {
        const newSession = await logSession({
          variables: {
            lastPath: url,
          },
        });

        if (!newSession.data?.logUserSession) {
          handleError(
            {
              message: 'Error creating session',
            },
            true
          );
          return;
        }
        localStorage.setItem('sessionId', newSession.data.logUserSession.id);
      } else {
        logSession({
          variables: {
            lastPath: url,
            userSessionId: sessionId,
          },
        });
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, []);

  if (currentUser?.loading) return <LoadingSpinner />;

  return <>{children}</>;
};
