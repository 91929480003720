import { captureException } from '@sentry/nextjs';

import { CurrentUser } from '@/auth/useCurrentUser';
import { EmergencyContact, Maybe, User, UserInvite } from '@/generated/graphql';

type UserLikeObject =
  | Maybe<User>
  | CurrentUser
  | Maybe<UserInvite>
  | (Maybe<EmergencyContact> & { middleName?: string })
  | undefined;

/**
 * Retrieves the variants of a user's name.
 *
 * @param {UserLikeObject} user - The user object.
 * @param {boolean} shouldSkipCheck - Indicates whether to skip the check for first and last name.
 * @returns {Object} - An object containing the variants of the user's name.
 * @property {string} firstName - The first name of the user. If not available, it will be set to '-'.
 * @property {string} fullName - The full name of the user. If any part is missing, it will be set to '-'.
 * @property {string} lastName - The last name of the user. If not available, it will be set to '-'.
 * @property {string} middleName - The middle name of the user. If not available, it will be set to '-'.
 */
export const getUserNameVariants = (
  user: UserLikeObject,
  shouldSkipCheck?: boolean
) => {
  if (!user) {
    return {
      firstName: '-',
      fullName: '-',
      lastName: '-',
      middleName: '-',
    };
  }

  if (!shouldSkipCheck && (!user.firstName || !user.lastName)) {
    const error = new Error(`Missing name parts: (${JSON.stringify(user)})`);

    // eslint-disable-next-line no-console
    console.error(error);

    captureException(error);
  }

  const userFullNameParts = [user.firstName, user.middleName, user.lastName];

  return {
    firstName: userFullNameParts[0] || '-',
    fullName: userFullNameParts.filter(Boolean).join(' ') || '-',
    lastName: userFullNameParts[2] || '-',
    middleName: userFullNameParts[1] || '-',
  };
};
